import React from 'react'
import './Loading.css'

const Loading = () => {
    return (
        <span className="loader"></span>
    )
}

export default Loading
